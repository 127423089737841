// import { Link } from "gatsby";
// import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Img from "gatsby-image";

const HeaderWrapper = styled.header`
  color: red;
  text-align: center;
  display: block;
  position: relative;
  width: 100vw;
`;

const NameWrapper = styled.h1`
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  font-weight: 100;
  padding-top: ${p => p.paddingTop || "1em"};
  padding-bottom: ${p => p.paddingBottom || "1em"};
  margin: 0;
  font-size: ${p => p.size}em;
  text-shadow: 0 0 3px rgba(0, 0, 0, 1);
  text-decoration: none;
`;

const HeaderLink = styled(Link)`
  text-decoration: none;
`;

export default ({ fluidImage }) => (
  <HeaderLink to="/">
    <HeaderWrapper>
      {fluidImage && (
        <Img
          fluid={fluidImage}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
          }}
        />
      )}
      <div style={{ transform: "scale(1)" }}>
        <NameWrapper paddingTop="2em" size={2}>
          GAL SCHLEZINGER
        </NameWrapper>
        <NameWrapper paddingBottom="4em" size={1}>
          <code>developer by day; rocker by night</code>
        </NameWrapper>
      </div>
    </HeaderWrapper>
  </HeaderLink>
);
