import React from "react";

export const SHOULD_USE_REVIEWERZ =
  process.env.NODE_ENV === "development" ||
  (process.env.GATSBY_COMMIT_REF && process.env.GATSBY_COMMIT_REF !== "master");

const root =
  process.env.NODE_ENV === "development"
    ? "https://localhost:3000"
    : "https://rvz.hagever.com";

const siteId =
  process.env.NODE_ENV === "development"
    ? "6887fb47-5d5a-4a9a-8e50-3ba8bd33c557"
    : "5c7895f5-6b7f-4cd7-9655-d73907faccd2";

export const useReviewerz = SHOULD_USE_REVIEWERZ
  ? function useReviewerz() {
      React.useEffect(() => {
        if (window.__reviewerz_loaded__) return;
        const script = document.createElement("script");
        script.id = "reviewerz-script";
        const id = encodeURIComponent(siteId);
        script.src = `${root}/widget.js?id=${id}&always-show`;
        script.defer = true;
        script.type = "module";
        document.head.appendChild(script);
        window.__reviewerz_loaded__ = true;
      }, []);
    }
  : function () {};
