import React from "react";
import styled from "styled-components";

const Footer = styled.footer`
  margin-top: 3em;
  line-height: 1.4;
`;

const PrettyLink = styled.a`
  color: #cf3232;
`;

const LinkTo = styled(({ className, onClick, href, handle, on }) => (
  <span className={className}>
    <PrettyLink onClick={onClick} href={href}>
      @{handle}
    </PrettyLink>{" "}
    on {on}
  </span>
))`
  display: block;
`;

const FooterTitle = styled.strong`
  font-weight: 600;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
`;

const alertLoveYou = e => {
  e.preventDefault();
  alert("*i love you*");
};

export default () => (
  <Footer>
    <FooterTitle>Let's chat!</FooterTitle>
    <LinkTo href="https://twitter.com/galstar" handle="galstar" on="twitter" />
    <LinkTo href="https://github.com/schniz" handle="Schniz" on="github" />
    <LinkTo
      href="https://www.linkedin.com/in/schlez"
      handle="Gal Schlezinger"
      on="linkedin"
    />
    <LinkTo href="#" onClick={alertLoveYou} handle="lover" on="life" />
  </Footer>
);
