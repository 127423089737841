import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

import { useReviewerz } from "./useReviewerz";
import Header from "./header";
import Footer from "./Footer";
import "./Layout.css";

const Layout = ({
  image: providedImage,
  fluidImage: providedFluidImage,
  children
}) => {
  useReviewerz();
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              description
              keywords
              title
              siteUrl
            }
          }

          headerImage: file(
            absolutePath: { glob: "**/src/images/header.jpg" }
          ) {
            childImageSharp {
              original {
                src
                height
                width
              }
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        const {
          title,
          description,
          keywords,
          siteUrl
        } = data.site.siteMetadata;
        const image =
          providedImage || data.headerImage.childImageSharp.original;
        const fluidImage =
          providedFluidImage || data.headerImage.childImageSharp.fluid;

        return (
          <>
            <Helmet
              title={title}
              meta={[
                {
                  name: "description",
                  content: description
                },
                {
                  name: "keywords",
                  content: keywords.join(", ")
                }
              ]}
            >
              <meta property="og:image" content={`${siteUrl}/${image.src}`} />
              <meta property="og:image:height" content={image.height} />
              <meta property="og:image:width" content={image.width} />
              <meta property="og:description" content={description} />
              <meta property="twitter:site" content="@galstar" />
              <meta property="twitter:creator" content="@galstar" />
              <meta name="twitter:card" content="summary" />
              <link
                rel="stylesheet"
                href="https://unpkg.com/firacode@1.205.0/distr/fira_code.css"
              />
              <html lang="en" />
            </Helmet>
            <Header fluidImage={fluidImage} />
            <div
              style={{
                margin: "0 auto",
                maxWidth: 960,
                padding: "0px 1.0875rem 1.45rem",
                paddingTop: "1em"
              }}
            >
              {children}
              <Footer />
            </div>
          </>
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
